* {
  box-sizing: border-box;
}

body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  align-items: center;
  background-color: #000;
  display: flex;
  justify-content: center;
  height: 100vh;
}

canvas {
  width: 100vw;
  height: 100vh;
  display: block;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.home {
  color: black;
  font-size: 22pt;
  font-weight:bold;
  font-family: 'courier new';
}

button {
  font-size: 22pt;
  background-color: gray;
  font-weight:bold;
  font-family: 'courier new';
}

.logo {
  height: 100%;
  width: 100%;
  position: absolute;
}

.prof_pic {
  max-width: 500pt;
}

.icon {
  filter: grayscale(100%) saturate(0%) brightness(70%) contrast(1000%);
  height: 60pt;
}

input {
  border-color: black;
  font-size: 22pt;
  box-sizing: border-box;
  color: black;
  font-family: 'Courier New', Courier, monospace;
}

.error {
  color: #dc2f55;
  font-size: 22pt;
}


.columns {
  display: flex;
  gap: 20px;
  font-size: 22pt;
}


.column-left {
  color: black;
  font-size: 22pt;
  font-weight:bold;
  font-family: 'courier new';
  width: 300px; /* Fixed width */
}

.column-right {
  color: black;
  justify-content: right;
  font-size: 22pt;
  font-weight:bold;
  font-family: 'courier new';
  flex: 1; /* Takes remaining space */
}